<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="卖品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称" />
					</a-form-item>

					<a-form-item label="卖品分类" name="classifyId" class="ui-form__item">
						<a-select placeholder="请选择卖品分类" v-model:value="formState.classifyId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in classifyList" :vlaue="item.id" :key="item.id">
								{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="状态" name="isShelf" class="ui-form__item">
						<a-select v-model:value="formState.isShelf" placeholder="请选择状态" allow-clear
							style="width: 190px;">
							<a-select-option :value="1">在售</a-select-option>
							<a-select-option :value="0">下架</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="卖品类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择卖品类型" allow-clear
							style="width: 190px;">
							<a-select-option :value="1">单品</a-select-option>
							<a-select-option :value="5">套餐</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="上架时间" name="createTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.createTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['snack_manage_cinema_export']">
							<exportReport type="storeGoodsStockExportStrategy" :searchData="searchData"></exportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id"
				:pagination="pagination" :scroll="{ x: 1800 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'img'">
						<a-image :src="record.imgUrl" style="width: 60px;"></a-image>
					</template>
					<template v-if="column.key === 'type'">
						<a-tag v-if="record.type === 1" color="blue">单品</a-tag>
						<a-tag v-if="record.type === 5" color="green">套餐</a-tag>
					</template>
					<template v-if="column.key === 'nowPrice'">
						{{ record.nowPrice }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-else-if="column.key === 'isShelf'">
						<a-tag v-if="record.isShelf" color="#87d068">在售</a-tag>
						<a-tag v-if="!record.isShelf" color="#f50">已下架</a-tag>
					</template>
					<template v-else-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a-button>
							<template #overlay>
								<a-menu>
									<div v-permission="['snack_manage_cinema_see']" @click="onSee(record)">
										<a-menu-item>
											查看
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_editNum']" v-if="record.type === 1"
										@click="onEdit(record)">
										<a-menu-item>
											编辑库存
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_editPrice']" @click="onPriceEdit(record)">
										<a-menu-item>
											编辑价格
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_record']" v-if="record.type === 1"
										@click="onBound(record)">
										<a-menu-item>
											出入库记录
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_disabled']" v-if="record.isShelf === 1"
										@click="onDisabled(record)">
										<a-menu-item>
											下架
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_open']" v-if="record.isShelf === 0"
										@click="onDisabled(record)">
										<a-menu-item>
											上架
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_promo']" @click="onShare(record, 1)">
										<a-menu-item>
											卖品二维码
										</a-menu-item>
									</div>
									<div v-permission="['snack_manage_cinema_delete']" @click="onDelete(record)">
										<a-menu-item>
											删除
										</a-menu-item>
									</div>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>

			<a-modal title="编辑库存" v-model:visible="isShowEdit" style="width: 450px;" @ok="onEditOk">
				<a-spin :spinning="loading">
					<div class="detail">
						<a-spin :spinning="loading">
							<a-form style="margin-top: 30px;" ref="editForm" :model="numModelRef" scrollToFirstError
								name="editForm" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
								<a-form-item label="当前库存">
									<span>{{ numModelRef.nowStock }}</span>
								</a-form-item>

								<a-form-item label="库存数量" name="stock"
									:rules="[{ required: true, message: '必填项不允许为空' }]">
									<a-input-number :precision="0" min="0" v-model:value="numModelRef.stock"
										placeholder="请输入库存数量" style="width: 200px"></a-input-number>
								</a-form-item>
							</a-form>
						</a-spin>
					</div>
				</a-spin>
			</a-modal>

			<a-modal title="编辑价格" v-model:visible="isShowEditPrice" style="width: 450px;" @ok="onEditPriceOk">
				<a-spin :spinning="loading">
					<div class="detail">
						<a-spin :spinning="loading">
							<a-form style="margin-top: 30px;" ref="editForm" :model="priceModelRef" scrollToFirstError
								name="editForm" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
								<!--                <a-form-item label="当前售价">
                  <span>{{ priceModelRef.nowPrice }}</span>
                </a-form-item>-->

								<a-form-item label="售价" name="nowPrice">
									<a-input-number :precision="2" min="0" v-model:value="priceModelRef.nowPrice"
										placeholder="请输入售价" style="width: 200px"></a-input-number>
								</a-form-item>
							</a-form>
						</a-spin>
					</div>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showShare" width="600px" title="卖品推广二维码">
				<template #footer>
					<a-button type="primary" @click="showShare = false">关闭</a-button>
				</template>
				<div style="text-align: center;">
					<a-image :src="imageUrl" style="width: 300px;height: 300px;"></a-image>
					<p style="margin-top: 40px;">点击鼠标右键保存二维码</p>
				</div>
			</a-modal>

			<a-modal v-model:visible="showBound" title="出入库记录" width="810px">
				<a-form ref="boundForm" name="boundForm" :model="boundFormState" @finish="onBoundSearch">
					<a-row>
						<a-form-item class="ui-form__item" label="时间" name="createTime">
							<a-range-picker v-model:value="boundFormState.createTime" />
						</a-form-item>
						<a-form-item class="ui-form__item" label="变动类型" name="logType">
							<a-select v-model:value="boundFormState.logType" placeholder="请选择变动类型" allow-clear
								style="width: 190px;">
								<a-select-option :value="1">上架影院</a-select-option>
								<a-select-option :value="2">编辑库存</a-select-option>
								<a-select-option :value="3">用户下单</a-select-option>
								<a-select-option :value="4">取消支付</a-select-option>
								<a-select-option :value="5">卖品核销</a-select-option>
								<a-select-option :value="6">用户退款</a-select-option>
							</a-select>
						</a-form-item>
					</a-row>
					<a-row>
						<a-col :span="18">
							<a-button type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
						</a-col>
						<a-col :span="6" style="text-align: right;">
							<a-button type="primary" html-type="submit">搜索</a-button>
							<a-button style="margin-left: 20px;" @click="boundReset">重置</a-button>
						</a-col>
					</a-row>
				</a-form>

				<div style="margin-top: 20px;">
					<a-table :columns="boundColumns" :dataSource="boundList" rowKey="id" :scroll="{ x: 500 }">
						<template #bodyCell="{ column, record }">
							<template v-if="column.key === 'createTime'">
								{{ transDateTime(record.createTime) }}
							</template>
							<template v-if="column.key === 'logType'">
								<div v-if="record.logType === 1">上架影院</div>
								<div v-if="record.logType === 2">编辑库存</div>
								<div v-if="record.logType === 3">用户下单</div>
								<div v-if="record.logType === 4">取消支付</div>
								<div v-if="record.logType === 5">卖品核销</div>
								<div v-if="record.logType === 6">用户退款</div>
							</template>
							<template v-if="column.key === 'logUserName'">
								<div v-if="record.logType !== 3 && record.logType !== 4 && record.logType !== 6">
									{{ record.logUserName || '-' }}
								</div>
								<div v-else>
									-
								</div>
							</template>
						</template>
					</a-table>
				</div>
				<template #footer>
					<a-button type="primary" @click="showBound = false;">确认</a-button>
				</template>
			</a-modal>
		</a-spin>
	</div>
	<publish v-if="showModal" :inventory="inventory" :isEdit="isEdit" :isSee="isSee" :id="id" :type="type" form="store"
		@back="onBack"></publish>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import publish from '@/views/snackManage/goods/publish/publish.vue';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import {
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getStoreGoodsList,
		pullOnShelvesStoreGoods,
		pullOffShelvesStoreGoods,
		shareGoodsQR
	} from '@/service/modules/goods.js';
	import {
		updateStoreGoods,
		updateNowPrice,
		deleteStoreGoods,
		getStoreGoodsStockLogList
	} from "@/service/modules/goods";
	export default {
		components: {
			Icon,
			exportReport,
			publish
		},
		data() {
			return {
				imageUrl: '',
				inventory: false,
				loading: false,
				showBound: false,
				isShowEdit: false,
				cinemaAllList: [],
				classifyList: [],
				numModelRef: {},
				formState: {},
				boundFormState: {},
				// status: 0,
				searchData: {},
				boundSearchData: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, {
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'img',
				}, {
					title: '卖品分类',
					dataIndex: 'classifyName',
				}, {
					title: '卖品类型',
					key: 'type',
				}, {
					title: '销售价',
					key: 'nowPrice',
				}, {
					title: '上架时间',
					key: 'createTime',
				}, {
					title: '总销量',
					dataIndex: 'soldNum',
				}, {
					title: '库存',
					dataIndex: 'stock',
				}, {
					title: '状态',
					key: 'isShelf',
				}, {
					title: '操作',
					key: 'action',
					width: 150,
					fixed: 'right'
				}],
				boundList: [],
				boundColumns: [{
					title: '变动时间',
					key: 'createTime'
				}, {
					title: '变动类型',
					key: 'logType'
				}, {
					title: '变动前库存',
					dataIndex: 'logBeforeNum',
				}, {
					title: '变动后库存',
					dataIndex: 'logAfterNum',
				}, {
					title: '操作人',
					key: 'logUserName',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				boundPagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.boundPagination.current = page;
						this.getBoundData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.boundPagination.current = 1;
						this.boundPagination.pageSize = pageSize;
						this.getBoundData();
					}
				},
				showModal: false,
				isEdit: true,
				isSee: false,
				id: 0,
				type: 2,
				showShare: false,
				isShowEditPrice: false,
				priceModelRef: {},
			}
		},
		created() {
			this.onSearch();
			this.getAllClassifyList();
			this.getAllCinemaList();
		},
		methods: {
			onEdit(item) {
				this.id = item.id;
				this.isShowEdit = true;
				this.numModelRef = {};
				this.numModelRef.nowStock = item.stock;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onBoundSearch() {
				this.boundPagination.current = 1;
				this.boundSearchData = JSON.parse(JSON.stringify(this.boundFormState));
				this.getBoundData();
			},
			boundReset() {
				this.$refs.boundForm.resetFields();
				this.onBoundSearch();
			},
			async getBoundData() {
				this.loading = true;
				if (this.boundFormState.createTime && this.boundFormState.createTime.length === 2) {
					this.boundSearchData.createStartTime = this.moment(this.boundFormState.createTime[0].startOf(
						'day')).unix();
					this.boundSearchData.createEndTime = this.moment(this.boundFormState.createTime[1].endOf('day'))
						.unix();
				}
				try {
					let ret = await getStoreGoodsStockLogList({
						goodsStockId: this.id,
						...this.boundSearchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.boundPagination.total = ret.data.totalCount;
						this.boundList = ret.data.list;
						this.showBound = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				if (this.searchData.createTime && this.searchData.createTime.length === 2) {
					this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
					this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
				}
				try {
					let ret = await getStoreGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 2,
						// status: this.status,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			async getAllClassifyList() {
				let ret = await getGoodsAllClassifyList({
					classifyType: 2
				});
				if (ret.code === 200) {
					let classifyList = ret.data.filter(item => {
						item.children = ret.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onSee(item) {
				this.inventory = true;
				this.id = item.id;
				this.isSee = true;
				this.showModal = true;
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确定' + (item.isShelf ? '下架' : '上架') + '该卖品吗？',
					onOk: async () => {
						let ret;
						this.loading = true;
						try {
							if (item.isShelf === 0) {
								ret = await pullOnShelvesStoreGoods({
									id: item.id
								})
							} else {
								ret = await pullOffShelvesStoreGoods({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isShelf ? '下架' : '上架') + '成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			async onShare(item, source) {
				this.loading = true;
				try {
					let ret = await shareGoodsQR({
						id: item.id,
						saleType: 2,
						source
					})
					this.loading = false;
					if (ret.code === 200) {
						this.imageUrl = ret.data.shareQR;
						this.showShare = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onEditOk() {
				if (!this.numModelRef.stock) {
					this.$message.error('请输入库存数量');
					return;
				}
				this.loading = true;
				try {
					let ret = await updateStoreGoods({
						id: this.id,
						stock: this.numModelRef.stock
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.isShowEdit = false;
						this.getData();
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onPriceEdit(item) {
				this.id = item.id;
				this.isShowEditPrice = true;
				this.priceModelRef = {};
				this.priceModelRef.nowPrice = item.nowPrice;
			},
			async onEditPriceOk() {
				if (this.priceModelRef.nowPrice && this.priceModelRef.nowPrice > 0) {
					this.loading = true;
					const ret = await updateNowPrice({
						nowPrice: this.priceModelRef.nowPrice,
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						this.id = 0;
						this.isShowEditPrice = false;
						this.getData();
					}
				} else {
					this.$message.error('请填写正确金额');
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确认要删除该影院卖品吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteStoreGoods({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onBound(item) {
				this.id = item.id;
				this.getBoundData();
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>